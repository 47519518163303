body, html {
  /* background-image: url('../images/wallpaperflare.com_wallpaper4.jpg'); */
  background-color: #a3d5ff;
  margin: 0;
  padding: 0;
  
  overflow: hidden;
  font-weight: 600;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}


.app {
  text-align: center;
  /* background-image: url('../images/wallpaperflare.com_wallpaper4.jpg'); */

  
  /* Set the root container to cover the entire viewport */
  width: 100%;
  height: 90%;
}

.bottom-center {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  padding: 5px;
  font-size: 20px;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
