

.hourly-forecast-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333; /* Text color */
}

.hourly-forecast-list {
  list-style: none;
  padding: 0;
}

/* Add a container for the hourly forecast with a fixed height and scroll overflow */
.hourly-forecast-container {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: hidden;
}

.hourly-forecast-item {
  border: 1px solid #A9A9A980;
  border-radius: 5px;
  background-color: #d9f0ff;
  margin: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF40;
  transition: transform 0.2s;
}

/* Styling the vertical scrollbar */
.hourly-forecast-container::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Styling the scrollbar track (the background) */
.hourly-forecast-container::-webkit-scrollbar-track {
  background: #FFFFFF40; /* Background color of the track */
}

/* Styling the scrollbar thumb (the draggable handle) */
.hourly-forecast-container::-webkit-scrollbar-thumb {
  background: #A9A9A980; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Styling the scrollbar thumb when hovered */
.hourly-forecast-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}

.hourly-forecast-item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.hour {
  font-size: 18px;
  font-weight: bold;
  color: #203f54; 
}

.temperature {
  font-size: 16px;
  color: #844c5d; 
}

.condition {
  font-size: 16px;
  color: #203f54; 
}

.humidity {
  font-size: 16px;
  color: #876867; 
}
