/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .home-container{
    max-width: 100%;
    padding: 10px;
  }
  .home-container h1 {
    font-size: 20rem + 50%; /* Adjust the font size for smaller screens */
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300%;
    height: 300%;
    padding: 20px;
  }

  .searchbar input, .searchbar button {
    width: 500%; /* Take up full width */
    padding: 5px; /* Reduce padding for smaller screens */
  }

  .LocInfo {
    max-width: 100%; /* Adjust the width for smaller screens */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .LocInfo h2 {
    font-size: 2em; /* Adjust the font size for smaller screens */
    margin-top: 10px; /* Adjust the margin for smaller screens */
    margin-bottom: 10px;
  }

  .LocInfo p {
    font-size: 1.5em; /* Adjust the font size for smaller screens */
  }

  .LocAdd {
    max-width: 100%; /* Adjust the width for smaller screens */
  }

  .LocAdd h2 {
    font-size: 2em; /* Adjust the font size for smaller screens */
    margin-top: 10px; /* Adjust the margin for smaller screens */
    margin-bottom: 10px;
  }

  .LocAdd p {
    font-size: 1.5em; /* Adjust the font size for smaller screens */
  }
}




.scrollable-content {
  max-height: 100vh; /* Set a maximum height to the viewport height */
  overflow-y: auto;  /* Enable vertical scrolling when content overflows */
}
/* Styling for Main Container */
.Main-container {
  color: #0c0c0c;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
}

/* Styling for Container Wrapper */
.container-wrapper {
  text-align: center;
  padding: 20px;
  }
  
  .home-container h1 {
    font-size: 3em;
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
  }
/* Styling for Home Container */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  margin: 0 auto;
  background-color: #d9f0ff;
  padding-bottom: 5px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style the input box */
.searchbar input {
  width: 90%;
  padding: 15px;
  font-size: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Style the search button */
.searchbar button {
  width: 60%;
  height: 60%;
  padding: 10px;
  font-size: 20px;
  background-color: #007BFF;
  margin-right: 5px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.searchbar button:hover {
  background-color: #0056b3;
}

/* Style the "Get Current Location" button */
.get-location-button {
  margin-top: 10px;
  background-color: #28a745;
}

/* Styling for Upcoming Container */
.Upcomming {
  color: #0c0c0c;
  padding: 25px;
  padding-top: 0;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  width: 140vh;
  margin: 20px;
}

.LocMap{
  text-align: center;
  padding: 20px;
  margin: 0;
}

/* Styling for Location Info */
.LocInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 51%;
  margin: 0 auto;
  background-color: #d9f0ff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.LocMap h2{
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 10px;
}

.LocMap p{
  font-size: 30px;
  margin-bottom: 0;
}

.map-container  {
   /* Remove flex: 1 */
   height: 30vh;
   width: 103vh; /* Adjust the height as needed */
   /* padding-left: 120px; */
   justify-content: center;
   align-items: center;
  }

/* Modify the parent container styles */
.map-parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Set width and height to 100% to occupy the available space */
  width: 100%;
  height: 100%;
}
.today-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 51%;
  margin: 0 auto;
  margin-top: 0;
  background-color: #d9f0ff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.today-heading {
  margin-top: 0;
  margin-bottom: 0;
}
/* Styling for Location Additional Info Container */
.LocAdd {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
}
.LocAdd h2{
  font-size: 30px;
}
.LocAdd p{
  font-size: 25px;
}

/* Styling for Weather Info Container */
.WeatherInfo {
  text-align: center;
  padding-left: 30px;
  margin-right: 20px;
  width: 200px;
}

/* Styling for Additional Info Container */
.AddInfo {
  text-align: center;
  margin: 10px;
  width: 200px;
  margin-top: 0;
}
.AddInfo h2{
  margin-top: 0;
}

.Hourly{
  padding-bottom: 300px;
}



